import '../scss/style.scss';
import { Carousel } from 'bootstrap';


const burger = document.querySelector('.burger');
const header = document.querySelector('.header');
const overlay = document.querySelector('.overlay');
const menu = document.querySelector('.header__navigation');
const closeBtn = document.querySelector('.close-btn');

function toggleMenu() {
    menu.classList.toggle('shown');
    overlay.classList.toggle('shown');
    header.classList.toggle('header_fixed');
}

burger.addEventListener('click', toggleMenu);
overlay.addEventListener('click', toggleMenu);
closeBtn.addEventListener('click', toggleMenu);

function togglePopup(){
    const popup = document.querySelector('.call-btn-popup');
    popup.classList.toggle('call-btn-popup_off');
    const phoneIcon = document.querySelector('.call-btn__phone');
    phoneIcon.classList.toggle('call-btn__phone_off')
} 

document.querySelector('.call-btn').addEventListener('click', togglePopup);

function cookieMessage(){
    const el = document.querySelector('.js-cookie-message');
  if (!localStorage.getItem('cookieAccept') || localStorage.getItem('cookieAccept') == 0) {
    el.style.display = 'block';     
      el.querySelector('.js-cookie-message-confirm').addEventListener('click', (e) => {
          localStorage.setItem('cookieAccept', 1);
          el.style.display = 'none';
      }, true);
  } else {
      el.style.display = 'none';        
  }
};
setTimeout(cookieMessage,3000);
